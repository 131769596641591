<template>
    <transition name="slide">
        <div
            class="tab-menu-1"
            :class="{
                'tab1-open': tab1,
                'tab2-open': tab2,
                'z-index': tab1,
            }"
        >
            <label class="tab-0">
                <input type="radio" class="tab-menu1-open" v-model="tab1" @change="CloseTab()" />
                <span>服務資訊</span>
            </label>
            <label class="tab-1">
                <input type="radio" class="tab-menu1-open" v-model="tab1" @change="OpenTab('tab2')" />
                <img src="@/assets/tgd/lightbox/file.svg" alt="" />
                <span>客戶資訊</span>
            </label>
            <div class="tab-menu1-wrap">
                <div class="tab-menu1">
                    <div class="news-wrap">
                        <div class="news-header">
                            <div class="news-title">
                                <img src="@/assets/tgd/index/coupon.svg" alt="" />
                                <span>剩餘票劵</span>
                            </div>
                        </div>
                        <div class="body remaining">
                            <p v-for="(remaining_ticket, index) in customerData.remaining_tickets" :key="index">
                                <span>{{ remaining_ticket.name }}</span>
                                <span>
                                    <strong>{{ remaining_ticket.count }}</strong
                                    >張
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="news-wrap">
                        <div class="news-header">
                            <div class="news-title">
                                <img src="@/assets/tgd/lightbox/record.svg" alt="" />
                                <span>消費紀錄</span>
                            </div>
                            <p>
                                累計消費次數:
                                <span>{{ customerData.consumptionNum }}</span>
                            </p>
                        </div>
                        <div class="news-body">
                            <div class="container">
                                <div
                                    class="row"
                                    v-for="(customerOrder, index) in customerData.customerOrderAry"
                                    :key="index"
                                >
                                    <div class="col-3">
                                        {{ customerOrder.consumptionDate }}
                                    </div>
                                    <div class="col-9">
                                        {{ customerOrder.serviceStr }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="news-wrap">
                        <div class="news-header">
                            <div class="news-title">
                                <img src="@/assets/tgd/lightbox/source.svg" alt="" />
                                <span>客戶來源</span>
                            </div>
                        </div>
                        <div class="body">
                            <BaseTextarea
                                placeholder="請輸入"
                                :name="sourceName"
                                :v-model="customerData[sourceName]"
                                :disabled="submitType == 2"
                            >
                                <div class="tag-wrap px-3">
                                    <button
                                        v-for="(field, idx) in fieldsSource"
                                        :key="field.key"
                                        class="tag"
                                        @click="removeSource(idx)"
                                        :disabled="submitType == 2"
                                    >
                                        {{ tagList.sourceTagObject[field.value] }}
                                    </button>
                                </div>
                            </BaseTextarea>
                            <div class="tag-wrap">
                                <button
                                    v-for="item in tagList.sourceTagList"
                                    :key="item.id"
                                    class="tag"
                                    @click="pushSource(item.id)"
                                    :disabled="submitType == 2"
                                >
                                    {{ item.name }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="news-wrap">
                        <div class="news-header">
                            <div class="news-title">
                                <img src="@/assets/tgd/lightbox/phone-book.svg" alt="" />
                                <span>客戶備註</span>
                            </div>
                        </div>
                        <div class="body">
                            <BaseTextarea
                                placeholder="請輸入"
                                :name="remarkName"
                                :v-model="customerData[remarkName]"
                                :disabled="submitType == 2"
                            >
                                <div class="tag-wrap px-3">
                                    <button
                                        v-for="(field, idx) in fieldsRemark"
                                        :key="field.key"
                                        class="tag"
                                        @click="removeRemark(idx)"
                                        :disabled="submitType == 2"
                                    >
                                        {{ tagList.remarkTagObject[field.value] }}
                                    </button>
                                </div>
                            </BaseTextarea>
                            <div class="tag-wrap">
                                <button
                                    v-for="item in tagList.remarkTagList"
                                    :key="item.id"
                                    class="tag"
                                    @click="pushRemark(item.id)"
                                    :disabled="submitType == 2"
                                >
                                    {{ item.name }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <transition name="slide">
        <div
            class="tab-menu-2"
            :class="{
                'tab1-open': tab1,
                'tab2-open': tab2,
                'z-index': tab2,
            }"
        >
            <label class="tab-2">
                <input type="radio" class="tab-menu2-open" v-model="tab2" @change="OpenTab('tab1')" />
                <img src="@/assets/tgd/lightbox/massage.svg" alt="" />
                <span>加強部位</span>
            </label>
            <div class="tab-menu2-wrap">
                <div class="tab-menu2">
                    <CheckoutOrderLocation
                        v-if="tab2"
                        :orderLocation="orderLocation"
                        :changeLocation="changeLocation"
                        :submitType="canChangeOrderLocation ? submitType: 2"
                    />
                    <div class="btn-wrap">
                        <button class="btn tab-menu2-close" @click="CloseTab, saveLocation()" v-show="canChangeOrderLocation && submitType != 2">
                            確認
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { reactive, computed, toRef, watch } from 'vue';
import { useFieldArray } from 'vee-validate';
import useTabOpen from '@/hooks/common/useTabOpen';
import CheckoutOrderLocation from '@/components/lightbox/CheckoutOrderLocation.vue';

export default {
    name: 'MemberSideLightbox',
    components: {
        CheckoutOrderLocation,
    },
    props: {
        customerData: {
            type: Object,
            required: true,
        },
        orderLocation: {
            type: Object,
            required: true,
        },
        submitType: {
            type: [Number, String],
        },
        values: {
            type: Object,
            required: true,
        },
        sourceTag: {
            type: Array,
            required: true,
        },
        remarkTag: {
            type: Array,
            required: true,
        },
        sourceName: {
            type: String,
            default: 'source',
        },
        remarkName: {
            type: String,
            default: 'remark',
        },
        canChangeOrderLocation: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['saveLocation'],
    setup(props, { emit }) {
        const { tab1, tab2, OpenTab, CloseTab } = useTabOpen();

        // 加強部位
        const orderLocation = toRef(props, 'orderLocation');

        const {
            remove: removeSource,
            push: pushSource,
            fields: fieldsSource,
            replace: replaceSource,
        } = useFieldArray('source_tag');

        const {
            remove: removeRemark,
            push: pushRemark,
            fields: fieldsRemark,
            replace: replaceRemark,
        } = useFieldArray('remark_tag');

        const sourceTag = computed(() => props.customerData.source_tag || []);
        const remarkTag = computed(() => props.customerData.remark_tag || []);

        watch(
            [sourceTag, remarkTag],
            ([sourceTag, remarkTag]) => {
                replaceSource(sourceTag);
                replaceRemark(remarkTag);
            },
            {
                immediate: true,
            }
        );

        const tagList = reactive({
            sourceTag: computed(() => {
                return props.values.source_tag || [];
            }),
            sourceTagList: computed(() => {
                return props.sourceTag.filter((item) => {
                    return !tagList.sourceTag.includes(item.id);
                });
            }),
            sourceTagObject: computed(() => {
                return props.sourceTag.reduce((prev, curr) => {
                    return { ...prev, [curr.id]: curr.name };
                }, {});
            }),
            remarkTag: computed(() => {
                return props.values.remark_tag || [];
            }),
            remarkTagList: computed(() => {
                return props.remarkTag.filter((item) => {
                    return !tagList.remarkTag.includes(item.id);
                });
            }),
            remarkTagObject: computed(() => {
                return props.remarkTag.reduce((prev, curr) => {
                    return { ...prev, [curr.id]: curr.name };
                }, {});
            }),
        });

        // // 勾選或取消勾選加強部位
        const changeLocation = (e) => {
            const inputValue = e.target.value.split('-');
            const inputCheckOrNot = e.target.checked;
            const block = inputValue[0];
            const type = inputValue[1];

            if (inputCheckOrNot) {
                orderLocation.value.data[block] = [];
                orderLocation.value.data[block].push(type);
            } else {
                orderLocation.value.data[block].splice(orderLocation.value.data[block].indexOf(type), 1);
            }
        };

        const saveLocation = () => {
            emit('saveLocation');
        };

        return {
            tab1,
            tab2,
            OpenTab,
            CloseTab,
            orderLocation,
            saveLocation,
            changeLocation,
            fieldsRemark,
            fieldsSource,
            pushRemark,
            pushSource,
            removeSource,
            removeRemark,
            tagList,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/css/reset';
@import '~@/css/mixins';
@import '~@/css/grid';
.slide-enter-active,
.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}

.tab1-open {
    right: 385px !important;
    @media (max-width: 1600px) {
        right: 0 !important;
    }
}
.tab2-open {
    right: 640px !important;
    @media (max-width: 1600px) {
        right: 0 !important;
    }
}
.z-index {
    z-index: 2 !important;
    @media (max-width: 1600px) {
        z-index: 4 !important;
    }
}

$tab-width: 120px;
$tab-menu-width: 895px;

.checkout-lightbox,
.add-reservation-lightbox {
    .tab-menu-1 {
        width: $tab-menu-width;
        position: absolute;
        min-height: 100%;
        right: -255px;
        top: 0;
        transition: ease-in 0.4s;
        .tab-0 {
            display: none;
            @media (max-width: 1600px) {
                display: flex;
            }
            position: absolute;
            left: -120px;
            width: $tab-width;
            height: 240px;
            background: #f8f8f8;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px 0px 0px 9px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            font: normal normal bold 16px/21px Microsoft JhengHei;
            letter-spacing: 0px;
            color: #707070;
            z-index: 1;
        }
        @media (max-width: 1600px) {
            &.tab1-open .tab-0,
            &.tab2-open .tab-0 {
                z-index: 0;
            }
        }
        .tab-1 {
            position: absolute;
            left: -120px;
            width: $tab-width;
            height: 240px;
            background: #e1e1e1;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px 0px 0px 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            font: normal normal bold 16px/21px Microsoft JhengHei;
            letter-spacing: 0px;
            color: #707070;
            @media (max-width: 1600px) {
                top: 240px;
            }
        }
        @media (max-width: 1600px) {
            &.z-index .tab-1 {
                box-shadow: 0px 3px 6px #00000029, 0px -3px 6px #00000029;
            }
        }
        .tab-menu1-wrap {
            overflow: overlay;
            height: 100vh;
            position: relative;
            width: 640px;
            background: #e1e1e1;
            box-shadow: 0px 3px 6px #00000029;
            @media (max-width: 1600px) {
                width: 100%;
            }
            &::-webkit-scrollbar-track {
                background-color: #d6d6d6;
                border-radius: 2px;
            }
            &::-webkit-scrollbar {
                width: 7px;
            }
            &::-webkit-scrollbar-thumb {
                background: #707070;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 9px;
            }

            .tab-menu1 {
                padding: 50px;
                padding-top: 60px;
                padding-bottom: 40px;
                position: absolute;
                background: #e1e1e1;
                width: 100%;
                .news-wrap {
                    .news-header {
                        display: flex;
                        align-items: center;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #a4641a;
                        .news-title {
                            font-size: 20px;
                            color: #a4641a;
                            display: flex;
                            align-items: center;
                            font-weight: bold;
                            span {
                                line-height: 22px;
                                padding-left: 10px;
                            }
                        }
                        p {
                            font: normal normal bold 16px/21px Microsoft JhengHei;
                            letter-spacing: 0px;
                            color: #4a4a4a;
                            padding-left: 35px;
                            span {
                                font: normal normal bold 20px/27px Open Sans;
                                letter-spacing: 0px;
                                color: #4a4a4a;
                            }
                        }
                    }
                    .news-body {
                        max-height: 325px;
                        overflow: overlay;
                        font: normal normal normal 16px/22px Open Sans;
                        letter-spacing: 0px;
                        color: #000000;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        .row {
                            padding: 10px 0;
                            border-bottom: 1px solid #ccc;
                        }
                        &::-webkit-scrollbar-track {
                            background-color: #d6d6d6;
                            border-radius: 2px;
                        }
                        &::-webkit-scrollbar {
                            width: 7px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background: #707070;
                            box-shadow: 0px 3px 6px #00000029;
                            border-radius: 9px;
                        }
                    }
                    .body {
                        padding: 20px 0 10px;
                        // padding-bottom: 60px;
                        // max-height: 160px;
                        &.remaining {
                            max-height: none;
                            padding-bottom: 20px;
                        }
                        p {
                            span {
                                padding-right: 30px;
                                font: normal normal normal 16px/21px Microsoft JhengHei;
                                letter-spacing: 0px;
                                color: #000000;
                                strong {
                                    padding-right: 12px;
                                    font: normal normal bold 22px/30px Open Sans;
                                    letter-spacing: 0px;
                                    color: #a4641a;
                                }
                            }
                        }
                        textarea {
                            width: 100%;
                            padding: 15px 30px;
                            background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
                                no-repeat padding-box;
                            border: 1px solid #ffffff;
                            border-radius: 11px;
                            font: normal normal bold 18px/24px Microsoft JhengHei;
                            letter-spacing: 0px;
                            color: #000000;
                            @include hover(90%);
                            &::-webkit-scrollbar-track {
                                background-color: #d6d6d6;
                                border-radius: 2px;
                            }
                            &::-webkit-scrollbar {
                                width: 7px;
                            }
                            &::-webkit-scrollbar-thumb {
                                background: #707070;
                                box-shadow: 0px 3px 6px #00000029;
                                border-radius: 9px;
                            }
                        }
                        .tag-wrap {
                            display: flex;
                            flex-wrap: wrap;
                            padding-top: 10px;
                        }
                        .tag {
                            color: #a4641a;
                            padding: 5px;
                            border: 1px solid #a4641a;
                            border-radius: 11px;
                            margin: 0 10px 10px 0;
                        }
                    }
                }
            }
        }
    }
    .tab-menu-2 {
        width: $tab-menu-width;
        min-height: 100%;
        background: #f8f8f8;
        position: absolute;
        right: -255px;
        top: 0;
        transition: ease-in 0.4s;
        box-shadow: 0px 3px 6px #00000029;
        .tab-2 {
            position: absolute;
            top: 240px;
            left: -120px;
            width: $tab-width;
            height: 240px;
            background: #f8f8f8;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px 0px 0px 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            font: normal normal bold 16px/21px Microsoft JhengHei;
            letter-spacing: 0px;
            color: #707070;
            @media (max-width: 1600px) {
                top: 480px;
            }
        }
        @media (max-width: 1600px) {
            &.z-index .tab-2 {
                box-shadow: 0px -3px 6px #00000029;
            }
        }
        .tab-menu2-wrap {
            overflow: overlay;
            height: 100vh;
            position: relative;
            width: 895px;

            &::-webkit-scrollbar-track {
                background-color: #d6d6d6;
                border-radius: 2px;
            }
            &::-webkit-scrollbar {
                width: 7px;
            }
            &::-webkit-scrollbar-thumb {
                background: #707070;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 9px;
            }
            .tab-menu2 {
                box-shadow: 0px 3px 6px #00000029;
                padding-top: 60px;
                padding-left: 45px;
                padding-right: 85px;
                padding-bottom: 50px;
                position: absolute;
                background: #f8f8f8;
                width: 100%;
                height: 100%;

                .btn-wrap {
                    position: absolute;
                    bottom: 50px;
                    .btn {
                        height: 70px;
                        width: 217px;
                        border-radius: 11px;
                        padding: 15px;
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #4a4a4a 0% 0% no-repeat padding-box;
                        font: normal normal bold 18px/24px Microsoft JhengHei;
                        letter-spacing: 0px;
                        filter: contrast(100%);
                        transition: all 0.3s;
                        &:hover {
                            filter: contrast(130%);
                        }
                    }
                }
            }
        }
    }
}
</style>
