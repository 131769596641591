<template>
    <div class="news-wrap">
        <div class="news-header">
            <div class="news-title">
                <img src="@/assets/tgd/lightbox/small-massage.svg" alt="" />
                <span>加強部位</span>
            </div>
        </div>
        <div class="news-body">
            <div class="row no-gutters title">
                <div class="col-4"></div>
                <div class="col-4 text-gold" style="position: relative; left: -35px">正面</div>
                <div class="col-4 text-gold">背面</div>
            </div>
            <div class="row no-gutters">
                <div class="col-6 news-body-left">
                    <div class="check-area container">
                        <div class="row no-gutters align-items-center">
                            <div class="col-6"></div>
                            <div class="col-3">輕</div>
                            <div class="col-3">重</div>
                        </div>
                        <div class="row no-gutters align-items-center pt-2">
                            <div class="col-6">頭部</div>
                            <div class="col-3">
                                <input
                                    id="head-1"
                                    :checked="orderLocation.data[1].indexOf(1) >= 0"
                                    :disabled="submitType == 2"
                                    name="head"
                                    type="radio"
                                    value="1-1"
                                    @change="changeLocation($event)"
                                />
                                <label for="head-1"></label>
                            </div>
                            <div class="col-3">
                                <input
                                    id="head-2"
                                    :checked="orderLocation.data[1].indexOf(2) >= 0"
                                    :disabled="submitType == 2"
                                    name="head"
                                    type="radio"
                                    value="1-2"
                                    @change="changeLocation($event)"
                                />
                                <label for="head-2"></label>
                            </div>
                        </div>
                        <div class="row no-gutters align-items-center" style="padding-top: 85px">
                            <div class="col-6">胸部</div>
                            <div class="col-3">
                                <input
                                    id="chest-1"
                                    :checked="orderLocation.data[2].indexOf(1) >= 0"
                                    :disabled="submitType == 2"
                                    name="chest"
                                    type="radio"
                                    value="2-1"
                                    @change="changeLocation($event)"
                                />
                                <label for="chest-1"></label>
                            </div>
                            <div class="col-3">
                                <input
                                    id="chest-2"
                                    :checked="orderLocation.data[2].indexOf(2) >= 0"
                                    :disabled="submitType == 2"
                                    name="chest"
                                    type="radio"
                                    value="2-2"
                                    @change="changeLocation($event)"
                                />
                                <label for="chest-2"></label>
                            </div>
                        </div>
                        <div class="row no-gutters align-items-center" style="padding-top: 75px">
                            <div class="col-6">腹部</div>
                            <div class="col-3">
                                <input
                                    id="belly-1"
                                    :checked="orderLocation.data[3].indexOf(1) >= 0"
                                    :disabled="submitType == 2"
                                    name="belly"
                                    type="radio"
                                    value="3-1"
                                    @change="changeLocation($event)"
                                />
                                <label for="belly-1"></label>
                            </div>
                            <div class="col-3">
                                <input
                                    id="belly-2"
                                    :checked="orderLocation.data[3].indexOf(2) >= 0"
                                    :disabled="submitType == 2"
                                    name="belly"
                                    type="radio"
                                    value="3-2"
                                    @change="changeLocation($event)"
                                />
                                <label for="belly-2"></label>
                            </div>
                        </div>
                        <div class="row no-gutters align-items-center" style="padding-top: 74px">
                            <div class="col-6">正大腿</div>
                            <div class="col-3">
                                <input
                                    id="thigh-1"
                                    :checked="orderLocation.data[4].indexOf(1) >= 0"
                                    :disabled="submitType == 2"
                                    name="thigh"
                                    type="radio"
                                    value="4-1"
                                    @change="changeLocation($event)"
                                />
                                <label for="thigh-1"></label>
                            </div>
                            <div class="col-3">
                                <input
                                    id="thigh-2"
                                    :checked="orderLocation.data[4].indexOf(2) >= 0"
                                    :disabled="submitType == 2"
                                    name="thigh"
                                    type="radio"
                                    value="4-2"
                                    @change="changeLocation($event)"
                                />
                                <label for="thigh-2"></label>
                            </div>
                        </div>
                    </div>
                    <div class="body-frant">
                        <img alt="" src="@/assets/tgd/lightbox/body-frant.svg" />
                    </div>
                </div>
                <div class="col-6 news-body-right">
                    <div class="body-back">
                        <img alt="" src="@/assets/tgd/lightbox/body-back.svg" />
                    </div>
                    <div class="check-area container">
                        <div class="row no-gutters align-items-center">
                            <div class="col-6"></div>
                            <div class="col-3">輕</div>
                            <div class="col-3">重</div>
                        </div>
                        <div class="row no-gutters align-items-center" style="padding-top: 59px">
                            <div class="col-6">脖子</div>
                            <div class="col-3">
                                <input
                                    id="neck-1"
                                    :checked="orderLocation.data[7].indexOf(1) >= 0"
                                    :disabled="submitType == 2"
                                    name="neck"
                                    type="radio"
                                    value="7-1"
                                    @change="changeLocation($event)"
                                />
                                <label for="neck-1"></label>
                            </div>
                            <div class="col-3">
                                <input
                                    id="neck-2"
                                    :checked="orderLocation.data[7].indexOf(2) >= 0"
                                    :disabled="submitType == 2"
                                    name="neck"
                                    type="radio"
                                    value="7-2"
                                    @change="changeLocation($event)"
                                />
                                <label for="neck-2"></label>
                            </div>
                        </div>
                        <div class="row no-gutters align-items-center" style="padding-top: 21px">
                            <div class="col-6">肩膀</div>
                            <div class="col-3">
                                <input
                                    id="shoulder-1"
                                    :checked="orderLocation.data[8].indexOf(1) >= 0"
                                    :disabled="submitType == 2"
                                    name="shoulder"
                                    type="radio"
                                    value="8-1"
                                    @change="changeLocation($event)"
                                />
                                <label for="shoulder-1"></label>
                            </div>
                            <div class="col-3">
                                <input
                                    id="shoulder-2"
                                    :checked="orderLocation.data[8].indexOf(2) >= 0"
                                    :disabled="submitType == 2"
                                    name="shoulder"
                                    type="radio"
                                    value="8-2"
                                    @change="changeLocation($event)"
                                />
                                <label for="shoulder-2"></label>
                            </div>
                        </div>
                        <div class="row no-gutters align-items-center" style="padding-top: 20px">
                            <div class="col-6">上背部</div>
                            <div class="col-3">
                                <input
                                    id="upper-back-1"
                                    :checked="orderLocation.data[9].indexOf(1) >= 0"
                                    :disabled="submitType == 2"
                                    name="upper-back"
                                    type="radio"
                                    value="9-1"
                                    @change="changeLocation($event)"
                                />
                                <label for="upper-back-1"></label>
                            </div>
                            <div class="col-3">
                                <input
                                    id="upper-back-2"
                                    :checked="orderLocation.data[9].indexOf(2) >= 0"
                                    :disabled="submitType == 2"
                                    name="upper-back"
                                    type="radio"
                                    value="9-2"
                                    @change="changeLocation($event)"
                                />
                                <label for="upper-back-2"></label>
                            </div>
                        </div>
                        <div class="row no-gutters align-items-center" style="padding-top: 17px">
                            <div class="col-6">中背部</div>
                            <div class="col-3">
                                <input
                                    id="mid-back-1"
                                    :checked="orderLocation.data[10].indexOf(1) >= 0"
                                    :disabled="submitType == 2"
                                    name="mid-back"
                                    type="radio"
                                    value="10-1"
                                    @change="changeLocation($event)"
                                />
                                <label for="mid-back-1"></label>
                            </div>
                            <div class="col-3">
                                <input
                                    id="mid-back-2"
                                    :checked="orderLocation.data[10].indexOf(2) >= 0"
                                    :disabled="submitType == 2"
                                    name="mid-back"
                                    type="radio"
                                    value="10-2"
                                    @change="changeLocation($event)"
                                />
                                <label for="mid-back-2"></label>
                            </div>
                        </div>
                        <div class="row no-gutters align-items-center" style="padding-top: 17px">
                            <div class="col-6">腰部</div>
                            <div class="col-3">
                                <input
                                    id="waist-1"
                                    :checked="orderLocation.data[11].indexOf(1) >= 0"
                                    :disabled="submitType == 2"
                                    name="waist"
                                    type="radio"
                                    value="11-1"
                                    @change="changeLocation($event)"
                                />
                                <label for="waist-1"></label>
                            </div>
                            <div class="col-3">
                                <input
                                    id="waist-2"
                                    :checked="orderLocation.data[11].indexOf(2) >= 0"
                                    :disabled="submitType == 2"
                                    name="waist"
                                    type="radio"
                                    value="11-2"
                                    @change="changeLocation($event)"
                                />
                                <label for="waist-2"></label>
                            </div>
                        </div>
                        <div class="row no-gutters align-items-center" style="padding-top: 73px">
                            <div class="col-6">後大腿</div>
                            <div class="col-3">
                                <input
                                    id="hind-thigh-1"
                                    :checked="orderLocation.data[12].indexOf(1) >= 0"
                                    :disabled="submitType == 2"
                                    name="hind-thigh"
                                    type="radio"
                                    value="12-1"
                                    @change="changeLocation($event)"
                                />
                                <label for="hind-thigh-1"></label>
                            </div>
                            <div class="col-3">
                                <input
                                    id="hind-thigh-2"
                                    :checked="orderLocation.data[12].indexOf(2) >= 0"
                                    :disabled="submitType == 2"
                                    name="hind-thigh"
                                    type="radio"
                                    value="12-2"
                                    @change="changeLocation($event)"
                                />
                                <label for="hind-thigh-2"></label>
                            </div>
                        </div>
                        <div class="row no-gutters align-items-center" style="padding-top: 73px">
                            <div class="col-6">後小腿</div>
                            <div class="col-3">
                                <input
                                    id="hind-calf-1"
                                    :checked="orderLocation.data[13].indexOf(1) >= 0"
                                    :disabled="submitType == 2"
                                    name="hind-calf"
                                    type="radio"
                                    value="13-1"
                                    @change="changeLocation($event)"
                                />
                                <label for="hind-calf-1"></label>
                            </div>
                            <div class="col-3">
                                <input
                                    id="hind-calf-2"
                                    :checked="orderLocation.data[13].indexOf(2) >= 0"
                                    :disabled="submitType == 2"
                                    name="hind-calf"
                                    type="radio"
                                    value="13-2"
                                    @change="changeLocation($event)"
                                />
                                <label for="hind-calf-2"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="center-check container p-0">
                <div class="row no-gutters align-items-center">
                    <div class="col-6"></div>
                    <div class="col-3">輕</div>
                    <div class="col-3">重</div>
                </div>
                <div class="row no-gutters align-items-center pt-2">
                    <div class="col-6">上手臂</div>
                    <div class="col-3">
                        <input
                            id="upper-arm-1"
                            :checked="orderLocation.data[5].indexOf(1) >= 0"
                            :disabled="submitType == 2"
                            name="upper-arm"
                            type="radio"
                            value="5-1"
                            @change="changeLocation($event)"
                        />
                        <label for="upper-arm-1"></label>
                    </div>
                    <div class="col-3">
                        <input
                            id="upper-arm-2"
                            :checked="orderLocation.data[5].indexOf(2) >= 0"
                            :disabled="submitType == 2"
                            name="upper-arm"
                            type="radio"
                            value="5-2"
                            @change="changeLocation($event)"
                        />
                        <label for="upper-arm-2"></label>
                    </div>
                </div>
                <div class="row no-gutters align-items-center" style="padding-top: 48px">
                    <div class="col-6">下手臂</div>
                    <div class="col-3">
                        <input
                            id="lower-arm-1"
                            :checked="orderLocation.data[6].indexOf(1) >= 0"
                            :disabled="submitType == 2"
                            name="lower-arm"
                            type="radio"
                            value="6-1"
                            @change="changeLocation($event)"
                        />
                        <label for="lower-arm-1"></label>
                    </div>
                    <div class="col-3">
                        <input
                            id="lower-arm-2"
                            :checked="orderLocation.data[6].indexOf(2) >= 0"
                            :disabled="submitType == 2"
                            name="lower-arm"
                            type="radio"
                            value="6-2"
                            @change="changeLocation($event)"
                        />
                        <label for="lower-arm-2"></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CheckoutOrderLocation',
    props: {
        orderLocation: {
            type: Object,
            required: true,
        },
        changeLocation: {
            type: Function,
            required: true,
        },
        submitType: {
            type: [Number, String],
            required: true,
        },
    },
    setup(props) {},
};
</script>

<style lang="scss" scoped>
@import '~@/css/reset';
@import '~@/css/mixins';
@import '~@/css/grid';
.news-wrap {
    .news-header {
        display: flex;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #a4641a;
        .news-title {
            font-size: 20px;
            color: #a4641a;
            display: flex;
            align-items: center;
            font-weight: bold;
            span {
                line-height: 22px;
                padding-left: 10px;
            }
        }
    }
    .news-body {
        font: normal normal bold 18px/24px Microsoft JhengHei;
        letter-spacing: 0px;
        color: #000000;
        position: relative;

        .title {
            padding-top: 45px;
            padding-bottom: 50px;
            .text-gold {
                color: #a4641a;
            }
        }
        .col-6 {
            display: flex;
            .check-area {
                width: 100%;
                position: relative;
                top: -10px;
                padding: 0;
                .no-gutters {
                    .col-3 {
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 10px;
                    }
                }
            }
        }
        .body-frant {
            margin-right: 30px;
        }
        .body-back {
            margin-left: 30px;
        }
        .center-check {
            width: 120px;
            position: absolute;
            top: 250px;
            left: 39%;
            .no-gutters {
                .col-3 {
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 10px;
                }
            }
        }
        input {
            display: none;
        }
        label {
            background: no-repeat center/contain url(~@/assets/tgd/lightbox/checkbox-none.svg);
            padding: 10px;
        }
        input:checked + label {
            background: no-repeat center/contain url(~@/assets/tgd/lightbox/checkbox-block.svg);
        }
    }
}
</style>
